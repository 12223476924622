define("ember-meta/services/head-data", ["exports", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/application"], function (_exports, _service, _object, _computed, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HeadDataService = (_dec = (0, _computed.alias)('router.currentRouteName'), _dec2 = (0, _object.computed)('routeName'), _dec3 = (0, _computed.reads)('currentRouteMeta.articleTitle'), _dec4 = (0, _computed.reads)('currentRouteMeta.author'), _dec5 = (0, _computed.reads)('currentRouteMeta.canonical'), _dec6 = (0, _computed.reads)('currentRouteMeta.categories'), _dec7 = (0, _computed.reads)('currentRouteMeta.content'), _dec8 = (0, _computed.reads)('currentRouteMeta.date'), _dec9 = (0, _object.computed)('content', 'currentRouteMeta.description', 'routeName', 'config.description'), _dec10 = (0, _object.computed)('currentRouteMeta.imgSrc', 'routeName', 'config.imgSrc'), _dec11 = (0, _object.computed)('currentRouteMeta.jsonld', 'routeName'), _dec12 = (0, _object.computed)('categories', 'routeName'), _dec13 = (0, _object.computed)('currentRouteMeta.siteName', 'routeName', 'config.siteName'), _dec14 = (0, _computed.reads)('currentRouteMeta.slug'), _dec15 = (0, _computed.reads)('categories'), _dec16 = (0, _object.computed)('currentRouteMeta.title', 'routeName', 'config.title'), _dec17 = (0, _object.computed)('currentRouteMeta.twitterUsername', 'routeName', 'config.twitterUsername'), _dec18 = (0, _object.computed)('currentRouteMeta.type', 'routeName'), _dec19 = (0, _object.computed)('currentRouteMeta.url', 'routeName', 'slug', 'config.url'), (_class = class HeadDataService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "routeName", _descriptor2, this);
      /**
       * Used for og:title, twitter:title as the title to show in the unfurled links
       */
      _initializerDefineProperty(this, "articleTitle", _descriptor3, this);
      /**
       * Used for twitter 'written by' meta.
       */
      _initializerDefineProperty(this, "author", _descriptor4, this);
      /**
       * Used for <link rel="canonical">
       */
      _initializerDefineProperty(this, "canonical", _descriptor5, this);
      /**
       * Internal - used by keywords & tags
       */
      _initializerDefineProperty(this, "categories", _descriptor6, this);
      /**
       * Internal - optionally used for description
       */
      _initializerDefineProperty(this, "content", _descriptor7, this);
      /**
       * Used for article:published_time
       */
      _initializerDefineProperty(this, "date", _descriptor8, this);
      /**
       * Internal - used for url
       */
      _initializerDefineProperty(this, "slug", _descriptor9, this);
      /**
       * Used for article:tag
       */
      _initializerDefineProperty(this, "tags", _descriptor10, this);
    }
    get config() {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return config['ember-meta'];
    }
    get currentRouteMeta() {
      const currentRoute = (0, _application.getOwner)(this).lookup(`route:${this.routeName}`);
      return currentRoute.metaInfo || currentRoute.currentModel;
    }
    /**
     * Used for <meta name="description">, og:description, twitter:description
     * This is the main content of your page, shown as the content in the unfurled links
     * If you pass a description, it will be used, otherwise it will truncate your content,
     * and finally it will use the description from the global config.
     */
    get description() {
      const description = this.currentRouteMeta?.description;
      const content = this.content;
      if (description) {
        return description;
      } else if (content && content.substring) {
        return `${content.substring(0, 260)}...`;
      }
      return this.config.description;
    }

    /**
     * Used for og:image twitter:image:src, the image to display in your unfurled links
     */
    get imgSrc() {
      return this.currentRouteMeta?.imgSrc ?? this.config.imgSrc;
    }
    get jsonld() {
      const jsonld = this.currentRouteMeta?.jsonld;
      if (jsonld) {
        return JSON.stringify(jsonld);
      }
      return false;
    }

    /**
     * Used for twitter meta to display 'filed under'
     */
    get keywords() {
      const categories = this.categories;
      return categories ? categories.join(', ') : null;
    }

    /**
     * Used for og:site_name
     */
    get siteName() {
      return this.currentRouteMeta?.siteName ?? this.config.siteName;
    }
    /**
     * Used for <title>, og:title, twitter:title
     */
    get title() {
      return this.currentRouteMeta?.title ?? this.config.title;
    }

    /**
     * Used for twitter:site and twitter:creator
     */
    get twitterUsername() {
      return this.currentRouteMeta?.twitterUsername ?? this.config.twitterUsername;
    }

    /**
     * Used for og:type, defaults to 'website'
     */
    get type() {
      return this.currentRouteMeta?.type ?? 'website';
    }

    /**
     * Used for <link rel="canonical">, og:url, twitter:url
     */
    get url() {
      let url = this.currentRouteMeta?.url ?? this.config.url;
      const slug = this.slug;
      if (slug) {
        url = `${url}${slug}/`;
      }
      return url;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "routeName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "currentRouteMeta", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "currentRouteMeta"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "articleTitle", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "author", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "canonical", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "categories", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "content", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "description", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "description"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "imgSrc", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "imgSrc"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "jsonld", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "jsonld"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "keywords", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "keywords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "siteName", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "siteName"), _class.prototype), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "tags", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "title", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "title"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "twitterUsername", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "twitterUsername"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "type", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "type"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "url", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "url"), _class.prototype)), _class));
  _exports.default = HeadDataService;
});